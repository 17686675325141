<template>
  <div class="container">
    <Promo title="Доставка цветов в Москве" />

    <div class="products">
      <ProductCard v-for="card in 4" :key="card" type="fulled" />
    </div>

    <Guarantee />

    <CardsAbstraction title="Цветочные коробки" link="/">
      <ProductCard v-for="card in 12" :key="card" type="default" />
    </CardsAbstraction>

    <CardsAbstraction no-border title="Хиты продаж" link="/">
      <ProductCard v-for="card in 12" :key="card" type="default" />
    </CardsAbstraction>

    <div class="center">
      <CButton tag="nuxt-link" to="/" large> Показать все цветы </CButton>
    </div>

    <p>
      Интернет-магазин Flor2u - это современный сервис и по-настоящему
      качественная доставка цветов в Москве. Мы предлагаем огромный выбор
      цветочных композиций и всегда рады помочь с покупкой эксклюзивного букета
      для любого повода.
    </p>

    <p>
      Не знаете, как впечатлить любимую женщину, что преподнести коллеге по
      работе, начальнику или подруге? Самое простое и беспроигрышное решение -
      купить цветы с доставкой. Сегодня наш ассортимент представлен:
    </p>

    <ul>
      <li>вечной классикой из сортовых роз, лилий и тюльпанов;</li>
      <li>экзотикой вроде орхидей, фрезий и гортензий;</li>
      <li>композициями с вкусными дополнениями и прочим декором.</li>
    </ul>

    <p>
      Мы выполняем доставку цветов день в день, поэтому можете быть уверены, что
      ваш сюрприз удастся. Букеты от Flor2u оригинальны, современны и изысканны.
      Среди имеющегося разнообразия легко подобрать вариант для дня рождения,
      юбилея, первого свидания и многих других важных событий. Наши курьеры
      привезут заказ по указанному адресу. Это может быть офис, дом, ресторан
      или другое место. Кроме того, доставка цветов круглосуточная и может быть
      анонимной.
    </p>

    <p>
      В нашем каталоге представлены цены на все товарные позиции. Но если вы
      хотите нечто особенное, то можете заказать букет цветов с доставкой на дом
      или работу, скомплектовав его на свое усмотрение. Над каждой композицией
      работают профессиональные флористы, которые уделяют особое внимание
      составу букета и его оформлению. Выбирайте цветы с бесплатной доставкой по
      Москве в магазине Flor2u - и вы останетесь довольны их качеством и нашим
      обслуживанием.
    </p>
  </div>
</template>

<script>
import { hydrateWhenVisible } from 'vue-lazy-hydration'
import CButton from '@/components/common/CButton'

const Promo = import(/* webpackChunkName: "promo" */ '@/components/Promo/')
const ProductCard = import(
  /* webpackChunkName: "product-card" */ '@/components/ProductCard/ProductCard'
)
const Guarantee = import(
  /* webpackChunkName: "guarantee" */ '@/components/Guаrantee'
)
const CardsAbstraction = import(
  /* webpackChunkName: "cards-abstraction" */ '@/components/ui/CardsAbstraction'
)

export default {
  name: 'Index',

  transition: 'transition-fade',

  components: {
    CButton,
    Promo: hydrateWhenVisible(() => Promo),
    ProductCard: hydrateWhenVisible(() => ProductCard),
    Guarantee: hydrateWhenVisible(() => Guarantee),
    CardsAbstraction: hydrateWhenVisible(() => CardsAbstraction),
  },
}
</script>

<style lang="scss" scoped>
.products {
  display: flex;
  flex-wrap: wrap;
  margin: rem(18) rem(-9) 0 rem(-9);
}

.center {
  display: flex;
  justify-content: center;
  margin-bottom: rem(36);
}
</style>
