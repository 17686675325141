<template>
  <component
    :is="tag"
    :class="['custom-button', classMap]"
    v-bind="attrs"
    v-on="$listeners"
  >
    <slot />
    <CLoader v-if="loading" />
  </component>
</template>

<script>
import CLoader from '@/components/common/CLoader'

const TAGS = ['button', 'nuxt-link', 'a']

export default {
  name: 'CustomButton',

  components: {
    CLoader,
  },

  props: {
    tag: {
      type: String,
      default: 'button',
      validator: (v) => !!TAGS.find((tag) => tag === v),
    },
    loading: Boolean,
    large: Boolean,
    icon: Boolean,
  },

  computed: {
    attrs() {
      const attrs = {}
      const isButton = this.tag === 'button'

      if (isButton) {
        attrs.type = 'button'
      }

      return { ...attrs, ...this.$attrs }
    },
    classMap() {
      return {
        'custom-button--large': this.large,
        'custom-button--icon': this.icon,
      }
    },
  },
}
</script>

<style lang="scss">
.custom-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: rem(108);
  padding: rem(9);
  color: #fff;
  font-size: rem(13);
  text-transform: uppercase;
  text-decoration: none;
  background-color: $dark-green3;
  border-radius: rem(5);
  box-shadow: 0 2px 0 $dark-green5;
  transition: background-color $default-transition;
  &:hover {
    background-color: $dark-green4;
  }

  &--large {
    margin: 0 auto;
    padding: rem(14) rem(54);
    font-weight: bold;
    font-size: rem(19);
    @media only screen and (max-width: $breakpoint-large-mobile) {
      padding: rem(14) rem(24);
    }
  }

  &--icon {
    min-width: unset;
  }
}
</style>
