import { render, staticRenderFns } from "./CLoader.vue?vue&type=template&id=be00c7ec&scoped=true&functional=true&"
var script = {}
import style0 from "./CLoader.vue?vue&type=style&index=0&id=be00c7ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "be00c7ec",
  null
  
)

export default component.exports